import React from 'react'
import CSS from './ServicesSection.module.css'

const ServicesSection = () => {
  return (
    <ServicesSectionTemplate
      heading='We always find ways to work together'
      description={
        <>
          <p>
            With Mashup Garage, we breathe technical challenges, we turn ideas
            into extraordinary products while making the whole process enjoyable
            from beginning to end.
          </p>
          <p>
            As technical leads with more than a decade of experience and working
            on different industries has taught us valuable knowledge that formed
            the foundation of Mashup Garage and its subsidiaries.
          </p>
        </>
      }
      services={
        <>
          <ServiceBlock
            heading='For Startups and Businesses'
            subheading='Extraordinary development teams'
            description='From building minimum-viable-products to managing existing products, 
                         we created product teams and managed them within Mashup Garage. 
                         We work with companies of different stages and together we built 
                         products and launched them.'
          />
          <ServiceBlock
            heading='Enterprise Solutions'
            subheading='True digital transformation and solutions'
            description='We have worked with companies such as Deloitte, Martha Stewart, 
                         Sanlam, Lonely Planet, and enterprises of various sizes. 
                         We have provided alternative software solutions for enterprises. 
                         Meticulously custom-built to fit the needs of each company.'
          />
          <ServiceBlock
            heading='Co-Founding and Technical Leadership'
            subheading='Technical experts with real experiences'
            description='From technical architecture, training, design, review, 
                         hiring, forming cultures, and setting up processes -- 
                         we can provide end-to-end analysis of your business and your products. 
                         Leave the technology to us and focus on the important aspects of your business instead.'
          />
        </>
      }
    />
  )
}

export interface TemplateProps {
  heading: React.ReactNode
  description: React.ReactNode
  services: React.ReactNode
}

const ServicesSectionTemplate = (props: TemplateProps) => {
  const { heading, description, services } = props
  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <div className={CSS.sectionInfo}>
          <h1 className={CSS.sectionHeading}>{heading}</h1>
          <div className={CSS.sectionDescription}>{description}</div>
        </div>
        <div className={CSS.services}>{services}</div>
      </div>
    </section>
  )
}

export interface ServiceBlockProps {
  heading: string
  subheading: string
  description: string
}

const ServiceBlock = (props: ServiceBlockProps) => {
  const { heading, subheading, description } = props
  return (
    <div className={CSS.service}>
      <h2 className={CSS.serviceHeading}>{heading}</h2>
      <div className={CSS.serviceSubheading}>{subheading}</div>
      <div className={CSS.serviceDescription}>{description}</div>
    </div>
  )
}

export { ServicesSectionTemplate }
export { ServiceBlock }
export default ServicesSection
