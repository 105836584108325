import React from 'react'
import CSS from './TestimonialSection.module.css'

const TestimonialSection = () => {
  return (
    <TestimonialSectionTemplate
      testimonial={
        <>
          <em>We work with founders around the globe.</em>{' '}
          {`We are constantly
          looking for people with big ideas who aren't afraid to build them.`}
        </>
      }
    />
  )
}

export interface TemplateProps {
  testimonial: React.ReactNode
}

const TestimonialSectionTemplate = (props: TemplateProps) => {
  const { testimonial } = props
  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <h1 className={CSS.testimonial}>{testimonial}</h1>
      </div>
      <div className={CSS.illo}>
        <div className={CSS.square} />
      </div>
    </section>
  )
}

export { TestimonialSectionTemplate }
export default TestimonialSection
