import React from 'react'
import Rellax from 'react-rellax'
import CSS from './TitleSection.module.css'

/*
 * Prop types
 */

export interface TemplateProps {
  headings: [React.ReactNode, React.ReactNode, React.ReactNode]
  preludes: [React.ReactNode, React.ReactNode, React.ReactNode]
}

/**
 * Title section
 */

const TitleSection = () => {
  return (
    <TitleSectionTemplate
      headings={['Agile', 'Software', 'Teams']}
      preludes={['BUILD && \\', 'LAUNCH && \\', 'REPEAT &']}
    />
  )
}

/**
 * Title section template
 */

const TitleSectionTemplate = (props: TemplateProps) => {
  const { headings } = props

  return (
    <section className={CSS.root}>
      <Rellax centered speed={-8}>
        <div className={CSS.container}>
          <div className={CSS.text}>
            <h1 className={CSS.line}>{headings[0]}</h1>
            <h1 className={CSS.line}>{headings[1]}</h1>
            <h1 className={CSS.line}>{headings[2]}</h1>
          </div>
        </div>
      </Rellax>
    </section>
  )
}

/*
 * Export
 */

export { TitleSectionTemplate }
export default TitleSection
