import React from 'react'
import iconOS from '../images/icons/open-source.svg'
import iconPhoenix from '../images/icons/phoenix.svg'
import iconRails from '../images/icons/rails.svg'
import iconReact from '../images/icons/react.svg'
import CSS from './ExpertiseSection.module.css'

const ExpertiseSection = () => {
  return (
    <ExpertiseSectionTemplate
      heading='Our Technology Stack'
      description='We pride ourselves on being pioneers of these technology stacks'
      setOfExpertise={
        <>
          <ExpertiseBlock
            icon={iconRails}
            title='Ruby on Rails'
            url='/rails'
            description='We are veterans of Ruby on Rails web development. Our team has collectively launched dozens of projects using Ruby on Rails. We developed tools and gems throughout our years.'
          />
          <ExpertiseBlock
            icon={iconPhoenix}
            title='Elixir and Phoenix'
            url='/elixir'
            description='Pioneering cutting edge web development. Just like our 
                         experience with Ruby on Rails 10 years ago, 
                         we love using new technologies that enables us to build products fast and effectively.'
          />
          <ExpertiseBlock
            icon={iconReact}
            title='React and React Native'
            url='/react'
            description='We love UI and UX that works. React enables us to develop solid components and interactions that users will love.'
          />
          <ExpertiseBlock
            icon={iconOS}
            title='Open Source'
            description='We have done amazing open source projects and very active on local and international community events.'
          />
        </>
      }
    />
  )
}

export interface TemplateProps {
  heading: string
  description: string
  setOfExpertise: React.ReactNode
}

const ExpertiseSectionTemplate = (props: TemplateProps) => {
  const { heading, description, setOfExpertise } = props
  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <div className={CSS.sectionInfo}>
          <h1 className={CSS.sectionHeading}>{heading}</h1>
          <div className={CSS.sectionDescription}>{description}</div>
        </div>
        <div className={CSS.listExpertise}>{setOfExpertise}</div>
      </div>
      <div className={CSS.illo}>
        <div className={CSS.core} />
      </div>
      <span className={CSS.spacerBottom} />
    </section>
  )
}

export interface ExpertiseBlockProps {
  icon: string
  title: string
  url?: string
  description: string
}

const ExpertiseBlock = (props: ExpertiseBlockProps) => {
  const { icon, title, url, description } = props

  const markup = (
    <>
      <div className={CSS.expertiseIcon}>
        <img src={icon} />
      </div>
      <div className={CSS.expertiseInfo}>
        <h2 className={CSS.expertiseTitle}>{title}</h2>
        <div className={CSS.expertiseDescription}>{description}</div>
      </div>
    </>
  )

  if (url) {
    return (
      <a href={url} className={CSS.expertise}>
        {markup}
      </a>
    )
  } else {
    // URL can be null for 'Open Source' :(
    return <span className={CSS.expertise}>{markup}</span>
  }
}

export { ExpertiseSectionTemplate }
export { ExpertiseBlock }
export default ExpertiseSection
