import React from 'react'
import mgImageA from '../images/mg-intro-A.jpg'
import mgImageB from '../images/mg-intro-B.jpg'
import CSS from './IntroSection.module.css'

/**
 * Intro section
 */

const IntroSection = () => {
  return (
    <IntroSectionTemplate
      preface={
        <>
          <em>We are a software development team</em> that brings together
          talents from product development, software engineering and interaction
          design.
        </>
      }
      imageA={mgImageA}
      imageB={mgImageB}
      heading={<>Agile teams for your startups and enterprises</>}
      body={
        <>
          <p>
            Our mission is to make products that deliver value to our customers
            and bring delightful experiences to users.
          </p>
          <p>
            To date, we have launched and co-founded startups in San Francisco,
            Melbourne, London, and Manila.
          </p>
        </>
      }
    />
  )
}

/*
 * Props
 */

export interface TemplateProps {
  preface: React.ReactNode
  imageA: string
  imageB: string
  heading: React.ReactNode
  body: React.ReactNode
}

/**
 * Intro section template
 */

const IntroSectionTemplate = (props: TemplateProps) => {
  const { preface, imageA, imageB, heading, body } = props

  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <div className={CSS.prefaceContainer}>
          <div className={CSS.preface}>{preface}</div>
        </div>
        <div className={CSS.bodyContainer}>
          <span className={CSS.bodyBorder1} />
          <span className={CSS.bodyBorder3} />
          <div className={CSS.text}>
            <h1 className={CSS.sectionHeading}>{heading}</h1>
            <div className={CSS.sectionDescription}>{body}</div>
          </div>
          <div className={CSS.images}>
            <div className={CSS.image}>
              <img src={imageA} />
            </div>
            <div className={[CSS.image, CSS.landscape].join(' ')}>
              <img src={imageB} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 * Export
 */

export { IntroSectionTemplate }
export default IntroSection
