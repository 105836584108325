import React from 'react'
import LargeFooterSection from '../sections/LargeFooterSection'
import TechnicalCofoundingSection from './CofoundingSection'
import ExpertiseSection from './ExpertiseSection'
import IntroSection from './IntroSection'
import Layout from './Layout'
import LocationSection from './LocationSection'
import { NavArea, NavLayout } from './Navigation'
import ServicesSection from './ServicesSection'
import TestimonialSection from './TestimonialSection'
import TitleSection from './TitleSection'

const IndexPage = () => {
  return (
    <Layout>
      <NavLayout>
        <NavArea dark id='title'>
          <TitleSection />
        </NavArea>

        <NavArea light id='intro'>
          <IntroSection />
        </NavArea>

        <NavArea darkAndLight id='services'>
          <ServicesSection />
        </NavArea>

        <NavArea dark id='expertise'>
          <ExpertiseSection />
        </NavArea>

        <NavArea light id='cofounding'>
          <TechnicalCofoundingSection />
        </NavArea>

        <NavArea dark id='testimonial'>
          <TestimonialSection />
          <LocationSection />
          <LargeFooterSection />
        </NavArea>
      </NavLayout>
    </Layout>
  )
}

export default IndexPage
