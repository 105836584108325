import React from 'react'
import Rellax from 'react-rellax'
import image from '../images/mg-cofounding.jpg'
import CSS from './CofoundingSection.module.css'

/**
 * Cofounding section
 */

const CofoundingSection = () => {
  return (
    <CofoundingSectionTemplate
      heading={<>We are technical co-founders</>}
      body={
        <>
          <p>We are entrepreneurs ourselves.</p>
          <p>
            We understand the importance of having someone in your team who
            understands the technical requirements of building your product.
          </p>
          <p>
            We have successfully founded companies and have been part of
            different stages of a startup.
          </p>
        </>
      }
    />
  )
}

/*
 * Prop types
 */

export interface TemplateProps {
  heading: React.ReactNode
  body: React.ReactNode
}

/**
 * Cofounding section template
 */

const CofoundingSectionTemplate = (props: TemplateProps) => {
  const { heading, body } = props
  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <div className={CSS.left}>
          <h2 className={CSS.sectionHeading}>{heading}</h2>
          <div className={CSS.sectionDescription}>{body}</div>
        </div>
        <div className={CSS.right}>
          <Rellax speed={-0.9} centered>
            <div className={CSS.image}>
              <img src={image} />
            </div>
          </Rellax>
        </div>
      </div>
    </section>
  )
}

/*
 * Export
 */

export { CofoundingSectionTemplate }
export default CofoundingSection
